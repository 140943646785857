import React from 'react';
import cookie from 'react-cookies';
import {
  Panel, ListGroup,
  ListGroupItem, Radio,
  FormControl, Form,
  Button, Checkbox, Row, Col,
  ProgressBar
} from 'react-bootstrap';


// radio
// {"poll":1925,"entity":null,"answers":[{"answer":2}]}
// checkbox
// {"poll":1925,"entity":null,"answers":[{"answer":1},{"answer":2}]}
// textarea
// {poll: 1925, entity: null, answers: [{answer: 2, answer_value: "variant"}]}


const ENTITIES_URL = '/edw/api/entities/';
const SUBMIT_URL = '/api/polls-results/';
const NETWORK_ERROR = 'Ошибка при отправке данных опроса.';


const RadioOrCheckbox = props => {
    if (props.max_answer === 1) {
        return (
            <Radio checked={props.checked} onClick={props.onClick} disabled={props.disabled} readOnly>
                {props.children}
            </Radio>
        );
    } else {
        return (
            <Checkbox checked={props.checked} onClick={props.onClick} disabled={props.disabled} readOnly>
                {props.children}
            </Checkbox>
        );
    }
};


function synchronousFetch(url) {
  return fetch(url)
    .then(response => response.json())
    .then(function(json) {
      return json;
    })
    .catch(function(error) {
        console.log(error);
    });
}

export default class PollWidget extends React.Component {

  componentWillMount() {
    const {pollId} = this.props;

    fetch(ENTITIES_URL + pollId)
      .then(r => r.json())
      .then(data => {
        const poll = {
          id: data.id,
          name: data.entity_name,
          completed: !!data.poll_result,
          is_valid: data.is_valid,
          is_poll_anonymous: data.anonymous,
          is_user_authorized: data.is_authorized
        };
        this.setState({
          poll,
          questions: data.questions,
          curQuestionIdx: 0
        });
      }).catch(e => {
        this.setState({loadError: true});
      });
  }

  submitPoll() {
    const { poll, questions } = this.state;

    const answers = [];
    for (const question of questions) {
      if (question.result && question.result.length) {
        for (const answer of question.result)
          answers.push(answer);
      }
    }

    const data = {
      poll: poll.id,
      answers
    };
    const cookie_csrftoken = cookie.load('csrftoken');

    fetch(SUBMIT_URL, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': cookie_csrftoken
      },
      body: JSON.stringify(data)
    }).then(result => {
      if (!result.ok) {
        alert(NETWORK_ERROR);
      } else {
        poll.completed = true;

        // add current votes to display overall results
        for (const question of questions) {
          for (const answer of question.answers) {
            if (answers.findIndex(item => item.answer == answer.id) > -1)
              answer.votes++;
          }
        }

        this.setState({poll, questions, curQuestionIdx: 0});
      }
    });
  }

  getPollProgress() {
    const { questions, curQuestionIdx } = this.state;
    return curQuestionIdx / (questions.length - 1) * 100;
  }

  prevQuestion() {
    const { curQuestionIdx } = this.state;
    if (curQuestionIdx > 0) {
      this.setState({curQuestionIdx: curQuestionIdx - 1});
    }
  }

  nextQuestion() {
    const { questions, curQuestionIdx } = this.state;
    if (curQuestionIdx < questions.length - 1) {
      this.setState({curQuestionIdx: curQuestionIdx + 1});
    } else {
      this.submitPoll();
    }
  }

  chooseAnswer(question, answer) {
    const { questions, curQuestionIdx } = this.state;

    if (!question.result)
      question.result = [];

    if (question.max_answer == 1) {
        question.result = [{answer: answer.id}];
    } else {
        const index = question.result.findIndex(item => item.answer == answer.id);
        if (index > -1) {
          question.result.splice(index, 1);
        } else {
          question.result.push({answer: answer.id});
        }
    }
    questions[curQuestionIdx] = question;
    this.setState({questions});
  }

  setAnswerValue(question, answer, value) {
    const { questions, curQuestionIdx } = this.state;
    const index = question.result.findIndex(item => item.answer == answer.id);
    if (index < 0)
      return;
    question.result[index].answer_value = value;
    questions[curQuestionIdx] = question;
    this.setState({questions});
  }

  getAnswerValue(question, answer, value) {
    const storedAnswer = question.result && question.result.find(item => item.answer == answer.id);
    return storedAnswer ? storedAnswer.answer_value || "" : "";
  }

  getIsChecked(question, answer) {
    return !!(question.result && question.result.findIndex(item => item.answer == answer.id) > -1);
  }

  getListItem(question, answer, key, checkedCount) {
    const { poll } = this.state;
    const checked = this.getIsChecked(question, answer);

    let ret = null;

    const is_readony = !poll.is_valid || poll.completed || (!poll.is_poll_anonymous && !poll.is_user_authorized);

    if (is_readony) {
      const percentage = question.totalVotes ? answer.votes / question.totalVotes * 100 : 0;
      ret = (
        <div>
        {answer.text ? answer.text : "Свой вариант"}
        <ProgressBar now={percentage}
          label={percentage ? `${parseFloat(percentage.toFixed(1))}%` : ''}/>
        </div>
      );
    } else {
      const disabled = question.max_answer > 1 && !checked && checkedCount >= question.max_answer;

      if (answer.text) {
        ret = (
          <RadioOrCheckbox checked={checked} disabled={disabled}
                           onClick={() => this.chooseAnswer(question, answer)}
                           max_answer={question.max_answer}>
            {answer.text}
          </RadioOrCheckbox>
        );
      } else {
        ret = (
          <Form>
              <RadioOrCheckbox
                  checked={checked}
                  disabled={disabled}
                  onClick={() => this.chooseAnswer(question, answer)}
                  max_answer={question.max_answer}>
                  Свой вариант
              </RadioOrCheckbox>
              <FormControl
                  disabled={!checked || disabled}
                  style={{marginBottom: 15}}
                  value={this.getAnswerValue(question, answer)}
                  onChange={event => this.setAnswerValue(question, answer, event.target.value)}
              />
          </Form>
        );
      }
    }

    return (
      <ListGroupItem key={key} className={checked ? "list-group-item-success" : ""}>
        {answer.media && <div dangerouslySetInnerHTML={{__html: answer.media}}/>}
        {ret}
      </ListGroupItem>
    );
  }

  render() {
    if (!this.state || !this.state.poll)
      return null;

    if (this.state.loadError)
      return <p>Ошибка загрузки опроса.</p>;

    const { poll, questions, curQuestionIdx } = this.state;
    if (!questions.length)
      return null;

    const question = questions[curQuestionIdx],
          isFirstQuestion = curQuestionIdx == 0,
          isLastQuestion = curQuestionIdx == questions.length - 1;

    let totalVotes = 0;
    let checkedCount = 0;

    const is_readony = !poll.is_valid || poll.completed || (!poll.is_poll_anonymous && !poll.is_user_authorized);

    if (is_readony) {
      for (const answer of question.answers)
        totalVotes += answer.votes;
    } else {
      for (const answer of question.answers) {
        if (this.getIsChecked(question, answer)) {
          checkedCount++;
        }
      }
    }

    question.totalVotes = totalVotes;

    let poll_state_info = null;
    if (!poll.is_poll_anonymous && !poll.is_user_authorized) {
        poll_state_info = <p>В этом опросе могут голосовать только зарегистрированные пользователи</p>;
    } else if (!poll.is_valid) {
        poll_state_info = <p>Опрос не находится в статусе голосования</p>;
    }

    let footer = null;
    if (!poll.completed || questions.length > 1) {
      footer = (
        <Panel.Footer className="text-center">
          <Row className="show-grid">
            <Col sm={4} xs={12}>
              <Button bsStyle="warning"
                      block disabled={isFirstQuestion}
                      onClick={() => this.prevQuestion()}>
                {is_readony ? "Предыдущий вопрос" : "Назад"}
              </Button>
            </Col>
            <Col sm={4} smOffset={4} xs={12}>
              <Button bsStyle="info"
                      block disabled={is_readony ? isLastQuestion : !checkedCount}
                      onClick={() => this.nextQuestion()}>
                {is_readony ? "Следующий вопрос" : isLastQuestion ? "Завершить" : "Далее"}
              </Button>
            </Col>
          </Row>
        </Panel.Footer>
      );
    }

    let pollProgress = null;
    if (questions.length > 1)
      pollProgress = <ProgressBar now={this.getPollProgress()} className="progress-bar-info"/>;

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title className="text-center">
            <h3 className="text-center">
              {poll.name}{is_readony && ". Результаты"}
            </h3>
            {poll_state_info}
          </Panel.Title>
          {pollProgress}
        </Panel.Heading>
        <Panel.Body>
          <p>{question.text}</p>
          <ListGroup style={{marginTop: 30}}>
          {question.answers.map((answer, key) => this.getListItem(question, answer, key, checkedCount))}
          </ListGroup>
        </Panel.Body>
        {footer}
      </Panel>
    );
  }
}
